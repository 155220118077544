import { useState, useEffect, Fragment } from "react"
import { Box, Paper, Typography, TextField, Button, Container, CircularProgress, Snackbar, Alert, FormControl, Select, Breadcrumbs, Link, IconButton } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";

import { EventiAPI } from "../../../../api/eventiApi";
import { RegistroAPI } from "../../../../api/registroApi";
import { convertDataGetProssimeLezione, convertToSqlFormat } from "../../../shared/metodiDateUtility";
import { PresenzeAPI } from "../../../../api/presenzeApi";
import ElencoAlunniSelezionabili from "../../../eventi/elencoAlunniSelezionabili";
import { UtilityAPI } from "../../../../api/utilityApi";
import { RicercaAPI } from "../../../../api/ricercaApi";
import { Check } from "@mui/icons-material";
import DialogConferma from "../../../shared/dialogConferma";



import { getAnnoFormativo } from "../../../shared/utilityMethods";


export default function AggiuntaExante(props) {

    const [data, setData] = useState(null);
    const [idDocente, setIdDocente] = useState(-1);
    const [idCorso, setIdCorso] = useState(-1);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("");
    const [dataFinePeriodo, setDataFinePeriodo] = useState("");

    const [materiaSelezionata, setMateriaSelezionata] = useState(-1);
    const [materiaSelezionataObj, setMateriaSelezionataObj] = useState(undefined)
    const [elencoMaterieDocente, setElencoMaterieDocente] = useState([]);

    const [TypeTipologia, setTipologia] = useState(-1);

    const [valType, setValType] = useState(0);

    const [listAlunni, setListAlunni] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [alunniNonSelezionabili, setAlunniNonSelezionabili] = useState([]);

    const [isDialog, setIsDialog] = useState(false);

    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)

    const [pulsanteConfermaDisabled, setPulsanteConfermaDisabled] = useState(false)
    const [annoFormativo, setAnnoFormativo] = useState("20242025");

    const [objPermessiExAnte, setObjPermessiExAnte] = useState({
        insertExAnte: true,
        prerequisitiPassaggio: false,
        prerequisitiRecupero: false
    })

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 75,
    });

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        if (props.idCorso != undefined && props.idDocente != undefined && props.alunniSelezionati !== undefined ) {
            let todayDate = DateTime.now();
            setData(todayDate)
            setAlunniSelezionati([...props.alunniSelezionati]);
            let annoFormativoTmp = getAnnoFormativo();
            setAnnoFormativo(annoFormativoTmp);
            retriveUserData(props.idAlunno, props.alunniSelezionati, annoFormativoTmp);
            setIdDocente(props.idDocente)
        }
    }, [props.idCorso, props.idDocente, props.listAlunni, props.alunniSelezionati, props.idAlunno]);

    useEffect(() => {
        if (props.dataInizioPeriodo !== undefined)
            setDataInizioPeriodo(DateTime.fromISO(props.dataInizioPeriodo));
    }, [props.dataInizioPeriodo])

    // useEffect(() => {
    //     if (props.annoFormativo !== undefined)
    //         setAnnoFormativo(props.annoFormativo)
    // }, [props.annoFormativo])
    useEffect(() => {
        if (props.dataFinePeriodo !== undefined)
            setDataFinePeriodo(DateTime.fromISO(props.dataFinePeriodo));
    }, [props.dataFinePeriodo])

    useEffect(() => {
        if (props.objPermessiExAnte !== undefined) {
            setObjPermessiExAnte(props.objPermessiExAnte)
        }
    }, [props.objPermessiExAnte])

    useEffect(() => {
        if (props.isDialog !== undefined) {
            setIsDialog(props.isDialog);
        }
    }, [props.isDialog])

    function selectAlunni(listAlunni) {

        let alunniSelezionatiTmp = alunniSelezionati;

        if (listAlunni !== undefined) {
            let arrayTmp = listAlunni;
            for (let i = 0; i < arrayTmp.length; i++) {
                let votoTmp = "";
                let valutazioneTmp = "";
                if (alunniSelezionatiTmp.some(alunno => alunno.id === arrayTmp[i].id)) {
                    let index = alunniSelezionatiTmp.findIndex(alunno => alunno.id === arrayTmp[i].id);
                    if (index !== -1) {
                        votoTmp = alunniSelezionatiTmp[index].voto;
                        valutazioneTmp = alunniSelezionatiTmp[index].valutazione;

                    }
                }
                arrayTmp[i].voto = votoTmp;
                arrayTmp[i].valutazione = valutazioneTmp;
            }
            // setAlunniSelezionati([...arrayTmp]);

            let test = arrayTmp.sort((a, b) => {
                if (a.n < b.n) {
                    return -1;
                }
                if (a.n > b.n) {
                    return 1;
                }
                return 0;
            });

            setAlunniSelezionati([...test]);
        }
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog();
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeData(value) {
        //setAlunniSelezionati([])
        setData(value);
        getAlunniPresenti(listAlunni, value, alunniSelezionati)
        //getOreLezione(value, idCorso, idDocente, idMateria, listAlunni, alunniSelezionati);
        //isAlunnoIscrittoInData(value, listAlunni);
    }

    function changeTypeVal(e) {
        setValType(e.target.value)
        let arrayTmp = alunniSelezionati;
        for (let a of arrayTmp) {
            a.voto = '';
        }
    }

    function changeTipologia(e) {
        setTipologia(e.target.value)
    }

    function valoreTipologia() {
        switch (TypeTipologia) {
            case -1:
                return '';
            case 0:
                return 'Ex ante';
            case 1:
                return 'Accertamento prerequisiti per recupero debito';
            case 2:
                return 'Accertamento prerequisiti per passaggio';
        }
    }

    function changeVoti(e, idAlunno) {

        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr === 0) newStr = 'N.V.';
        } else {
            newStr = '' //resetta a ''
        }

        let alunniSelezionatiTmp = alunniSelezionati;
        let index2 = alunniSelezionatiTmp.findIndex((element) => element.id === idAlunno);
        alunniSelezionatiTmp[index2].voto = newStr
        setAlunniSelezionati([...alunniSelezionatiTmp])
    }


    function changeMateria(e) {
        setMateriaSelezionata(e.target.value);
        let indexMateria = elencoMaterieDocente.findIndex((m => m.idMateria === e.target.value))
        if (indexMateria !== -1) {
            let materiaObj = {
                nomeMateria: elencoMaterieDocente[indexMateria]?.nomeMateria ?? "",
                nomeBreveMateria: elencoMaterieDocente[indexMateria]?.nomeMateriaBreve ?? "",
                idMateria: elencoMaterieDocente[indexMateria]?.idMateria ?? -1
            }
            setMateriaSelezionataObj(materiaObj)
        } else setMateriaSelezionataObj(undefined)
    }


    function votoIsNotValid() {
        return alunniSelezionati.findIndex((element) => element.voto === '') !== -1 ? true : false
    }



    function isAlunnoIscrittoInData(data, listAlunni) {
        if (data !== null) {
            let dataConvertedIso = data.toUTC().toISO()
            let listTmp = [];
            for (let alunno of listAlunni) {
                let iscrittoDalIso = alunno?.iscrittoDal;
                let iscrittoAlIso = alunno?.iscrittoAl;

                let startDate = DateTime.fromISO(iscrittoDalIso);
                let endDate = DateTime.fromISO(iscrittoAlIso).plus({ days: 1 });
                let someDate = DateTime.fromISO(dataConvertedIso);
                let interval = Interval.fromDateTimes(startDate, endDate);

                if (!interval.contains(someDate)) {
                    listTmp.push(alunno);
                }
            }
            return listTmp; // setAlunniNonSelezionabili([...listTmp]);
        }
    }

    function isAddButtonDisabled() {
        if (alunniSelezionati.length > 0 && data != null && (materiaSelezionataObj?.idMateria !== undefined && materiaSelezionataObj?.idMateria != -1)) {
            if (!votoIsNotValid() && TypeTipologia !== -1)
                return false
            else return true
        } else return true
    }


    async function getElencoAlunni(idCorso, idSede, alunniSelezionati, annoFormativoTmp) { //Viene chiamato solo dal dettaglio alunno

        let dataObj = {
            annoFormativo: annoFormativoTmp ?? annoFormativo,
            idSede: idSede, //TODO: Per ora è sempre impostata a 4
            idCorso: idCorso,
        }
        RicercaAPI.getAlunni(dataObj)
            .then((result) => {
                let risultati = result.sedi[0]?.corsi[0]?.alunni ?? [];

                let newRisultati = [];
                if (risultati.length > 0) {
                    for (let alunno of risultati) {
                        let newObj = {
                            id: alunno?.idAlunno,
                            nome: alunno?.nomeAlunno,
                            cognome: alunno?.cognomeAlunno,
                            iscrittoDal: alunno?.corsoDal ?? null,
                            iscrittoAl: alunno?.corsoAl ?? null
                        }
                        newRisultati.push(newObj);
                    }
                    getAlunniPresenti(newRisultati, DateTime.now(), alunniSelezionati);
                } else {
                    setAlunniSelezionati([])
                }
                setListAlunni([...newRisultati]);
                setGetAlunniIsLoading(false);
            })
    }
    async function getAlunniPresenti(alunniTmp, dataRichiesta, alunniSelezionati) {

        let dataSql = convertToSqlFormat(dataRichiesta);

        let idAlunni = [];
        for (let alunno of alunniTmp) {
            idAlunni.push(alunno?.id)
        }

        let dataObj = {
            "data": dataSql,
            "idAlunno": idAlunni
        }

        PresenzeAPI.getPresenzeGiornaliere(dataObj)
            .then((result) => {
                let alunniNonSelezionabiliTmp = [];
                for (let alunno of result.alunni) {
                    if (alunno?.stato_presenza === "A") {
                        let indexAlunno = alunniTmp.findIndex(a => a.id === alunno?.idAlunno);
                        let objAlunno = alunniTmp[indexAlunno];
                        objAlunno.presenza = true;
                        alunniNonSelezionabiliTmp.push(objAlunno)
                    }
                }

                let newAlunni = isAlunnoIscrittoInData(dataRichiesta, alunniTmp);
                for (let alunno of newAlunni) {
                    alunniNonSelezionabiliTmp.push(alunno);
                }

                setAlunniNonSelezionabili([...alunniNonSelezionabiliTmp]);
                let arrayTmp = alunniNonSelezionabiliTmp;
                let arraySelTmp = []


                let alunniSelezionabiliNumber = alunniTmp.length - alunniNonSelezionabiliTmp.length
                if (alunniSelezionabiliNumber < 0)
                    alunniSelezionabiliNumber = 0;

                setAlunniSelezionabiliNumber(alunniSelezionabiliNumber)
                for (let alunno of alunniSelezionati) {
                    if (!arrayTmp.includes(alunno)) arraySelTmp.push(alunno);
                }
                setAlunniSelezionati([...arraySelTmp]);
                selectAlunni(arraySelTmp);
            })
            .catch((error) => {
            })

    }
    async function inserisciExAnte() {

        let alunniSelezionatiTmp = alunniSelezionati;
        let votiFalliti = [];
        for (let alunno of alunniSelezionati) {

            const obj = {
                voto: alunno?.voto === 'N.V.' ? "0" : alunno?.voto,
                note: '',
                idAlunno: [alunno?.id],
                idOrario: null,
                annoFormativo: annoFormativo,
                dataRichiesta: convertDataGetProssimeLezione(data, true),
                idMateria: materiaSelezionataObj?.idMateria ?? -1,
                nomeMateria: materiaSelezionataObj?.nomeMateria ?? "",
                nomeBreveMateria: materiaSelezionataObj?.nomeBreveMateria ?? "",
                tipologia: valoreTipologia()
            }

            setPulsanteConfermaDisabled(true);

            let result = await insertExante(obj);
            if (result != null) {
                votiFalliti.push(result)
            }

        }

        if (votiFalliti.length === 0) {
            if (isDialog) {
                if (props.votoAggiunto)
                    props.votoAggiunto("Ex ante aggiunto", "success");
                else {
                    setAlunniSelezionati([]);
                    setAlertSeverity("success");
                    setAlertMsg("Ex ante aggiunto");
                    setAlertIsOpen(true);
                }
            } else {
                setAlunniSelezionati([]);
                setAlertSeverity("success");
                setAlertMsg("Ex ante aggiunto");
                setAlertIsOpen(true);
            }
        } else {
            for (let i = 0; i < alunniSelezionatiTmp.length; i++) {
                let indexVoti = votiFalliti.findIndex(a => a.id === alunniSelezionatiTmp[i].id)
                if (indexVoti !== -1)
                    alunniSelezionatiTmp[i].msgError = votiFalliti[indexVoti].errorMsg;
            }

            let newAlunniSelezionati = alunniSelezionatiTmp.filter(a => a.msgError !== undefined)
            setAlunniSelezionati([...newAlunniSelezionati])
        }
    }
    async function retriveUserData(idAlunno, alunniSelezionatiTmp, annoFormativoTmp) {
        let dataObj = {
            idAlunno: idAlunno,
            annoFormativo: annoFormativoTmp ?? annoFormativo
        }

        UtilityAPI.retriveDataAlunno(dataObj)
            .then((result) => {
                setIdCorso(result.corso.idCorso)
                getElencoAlunni(result.corso.idCorso, result.corso.idSede, alunniSelezionatiTmp, annoFormativoTmp ?? annoFormativo);
                getMaterieDocente(result.corso.idCorso)

            })
            .catch((error) => { })
    }
    async function getMaterieDocente(idCorsoTmp) {
        let dataObj = {
            idCorso: parseInt(idCorsoTmp),
            showMateriaValutazione: false,
            isExAnte: true
        }
        RegistroAPI.getElencoMateriePerClasseDocente(dataObj)
            .then((result) => {
                setElencoMaterieDocente([...result?.materie]);

            })
            .catch((error) => { })
    }

    async function insertExante(obj) {
        try {
            await EventiAPI.insertExante(obj);
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)
            return null;

        } catch (error) {
            setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setPulsanteConfermaDisabled(false);
            }, 500)

            let errorMsgTmp = "";

            if (error && error.response && error.response.status == 409) {
                errorMsgTmp = error?.response?.data?.data[0]?.msg ?? "Impossibile registrare più di un voto per alunno nella stesso giorno di lezione";
            } else {
                errorMsgTmp = "Al momento non è possibile completare l'operazione";
            }

            return {
                id: obj.idAlunno[0],
                errorMsg: errorMsgTmp
            };

        }
    }

    function rowRenderer({ index, key, parent, style }) {
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => (

                    <div onLoad={measure} style={style}>
                        <Grid container spacing={2} mb={1} sx={{ p: 1, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderBottom: 'solid 1px rgb(210,210,210)' }}>
                            <Grid xs={12} md={8} sx={{ width: '100%' }}>
                                <Typography>{alunniSelezionati[index]?.cognome} {alunniSelezionati[index]?.nome}  </Typography>
                                {alunniSelezionati[index]?.msgError ? (
                                    <Typography variant="subtitle2" color="error">{alunniSelezionati[index]?.msgError}</Typography>
                                ) : null}
                            </Grid>
                            <Grid xs={12} md={4} sx={{ width: '100%' }}>
                                <TextField sx={{ width: '100%' }} data-testid='votoInput'
                                    label="Voto"
                                    onChange={(e) => changeVoti(e, alunniSelezionati[index]?.id)}
                                    value={alunniSelezionati[index]?.voto ? alunniSelezionati[index]?.voto : ''}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </CellMeasurer>
        )

    }

    return (
        <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} maxWidth="xl">
                {!isDialog ? (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="plain" href='/' sx={{ cursor: 'pointer' }}>Home</Link>
                        <Typography color="text.disabled">Aggiunta eventi</Typography>
                        <Typography color="text.disabled">Voto</Typography>
                    </Breadcrumbs>) : null}

                <Grid container spacing={2}>
                    <Grid xs={12} md={6} alignItems="stretch">
                        {!getAlunniIsLoading ? (
                            <ElencoAlunniSelezionabili alunniSelezionabiliNumber={alunniSelezionabiliNumber} checkAssenze={true} canSelezioneMultipla={true} selectAlunni={selectAlunni} alunniNonSelezionabili={alunniNonSelezionabili} listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                        ) : (
                            <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                <CircularProgress />
                                <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                            </Paper>
                        )}
                    </Grid>
                    <Grid xs={12} md={6} alignItems="stretch" direction="column" justifyContent="flex-end">
                        <Box sx={{ display: "flex", flexDirection: "column", height: '100%' }}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                            maxDate={DateTime.now()}
                                            label="Data"
                                            value={data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeData(value)}
                                            // onAccept={changeData}
                                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={6} sx={{ width: '100%', pt: { xs: 1, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="materia-label">
                                            Materia
                                        </InputLabel>
                                        <Select
                                            label='Materia'
                                            labelId="materia-label"
                                            value={materiaSelezionata}
                                            onChange={changeMateria}
                                            disabled={elencoMaterieDocente.length === 0}
                                        >
                                            <MenuItem key={-1} value={-1}> </MenuItem>
                                            {elencoMaterieDocente.map((materia) => (
                                                <MenuItem key={materia.idMateria} value={materia.idMateria} >
                                                    {materia?.nomeMateria}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {data != null && elencoMaterieDocente.length === 0 ? (<Typography variant="caption">Nessuna materia associata a questo corso</Typography>) : null}
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid xs={12} md={12} sx={{ width: '100%', pt: { xs: 2, md: 3 } }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="TypeTipologia-label">
                                            Tipologia
                                        </InputLabel>
                                        <Select
                                            data-testid='selTypeTipologiaSelect'
                                            labelId="TypeTipologia-label"
                                            label='Tipologia'
                                            value={TypeTipologia}
                                            onChange={changeTipologia}
                                        >
                                            <MenuItem key={-1} value={-1} >

                                            </MenuItem>
                                            {objPermessiExAnte?.insertExAnte !== undefined && objPermessiExAnte?.insertExAnte === true && (
                                                <MenuItem key={0} value={0} >
                                                    Ex ante
                                                </MenuItem>
                                            )}
                                            {objPermessiExAnte?.prerequisitiPassaggio !== undefined && objPermessiExAnte?.prerequisitiPassaggio === true && (
                                                <MenuItem key={2} value={2} >
                                                    Accertamento prerequisiti per passaggio
                                                </MenuItem>
                                            )}
                                            {objPermessiExAnte?.prerequisitiRecupero !== undefined && objPermessiExAnte?.prerequisitiRecupero === true && (
                                                <MenuItem key={1} value={1} >
                                                    Accertamento prerequisiti per recupero debito
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid xs={12} md={12}>
                                <Typography mb={1}>Lista alunni selezionati</Typography>
                            </Grid>
                            <Grid container sx={{ height: { xs: '50vh', md: "100%" } }}>
                                <Grid xs={12} md={12} sx={{ height: '100%' }}>
                                    <Container mt={1} sx={{ p: 1, border: '1px solid rgba(200, 200, 200, 1)', height: '100%', overflow: 'hidden', borderRadius: '4px' }}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    deferredMeasurementCache={cache}
                                                    height={height}
                                                    rowCount={alunniSelezionati.length}
                                                    rowHeight={cache.rowHeight}
                                                    rowRenderer={rowRenderer}
                                                    width={width}
                                                />
                                            )}
                                        </AutoSizer>

                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"end"} sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 3, width: '100%' }}>
                    {isDialog ? (
                        <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">Annulla</Button>
                    ) : null}
                    <Button onClick={() => setConfirmAlertIsOpen(true)} disabled={isAddButtonDisabled()} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                        color="success" variant="contained" >
                        Aggiungi ex ante
                    </Button>
                </Box>
            </Container>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma aggiunta exante'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere un exante a `}
                showAlunni={true}
                listAlunniSelezionati={alunniSelezionati}
                listAlunniLength={alunniSelezionabiliNumber}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={inserisciExAnte}
                disabledButton={pulsanteConfermaDisabled}
            />

            {/* <Dialog open={confirmAlertIsOpen} onClose={closeAlertConfirmInserimento} >
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Conferma aggiunta ex-ante
                    </Typography>
                    <IconButton onClick={closeAlertConfirmInserimento} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler aggiungere un voto a {
                            alunniSelezionati.length === listAlunni.length ? (
                                <Typography><b>Tutta la classe</b></Typography>
                            ) : (
                                alunniSelezionati.map((alunno) => (
                                    <Typography key={alunno?.id}><b>{alunno?.nome} {alunno?.cognome}</b></Typography>
                                ))
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlertConfirmInserimento}>Annulla</Button>
                    <Button onClick={inserisciExAnte} disabled={pulsanteConfermaDisabled} autoFocus>
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}